.faqAccordionColspan {
    @apply col-span-2 mb-4 lg:col-span-1 border-makdosSecondaryGray
}

.faqTitleStyle {
    @apply flex items-center justify-between cursor-pointer
}

.faqChildrenStyle {
    @apply p-3 border-2 border-t-0 rounded-md rounded-t-none text-cardText
}