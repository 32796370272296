.fade-in-up {
    opacity: 0; 
    transform: translateY(-20px); 
    transition: opacity 1s ease-out, transform 1s ease-out;
}

.fade-in-up.visible {
    opacity: 1; 
    transform: translateY(0); 
}

.iysSteps {
    @apply col-span-3 py-2 mr-4 font-medium text-center border rounded-full lg:col-span-2 px-7 bg-stepGreen border-alertGreen text-alertGreen h-fit
}

.iysEntegration {
    @apply flex justify-center items-center p-2 w-6 h-6 text-white bg-green-500 rounded-full
}

.iysEntegrationEffect {
    @apply transition-all duration-1000 transform -translate-y-10 opacity-0 fade-in-up
}

.iysEntgFlex {
    @apply flex pt-6 space-x-4
}

.flexCenter {
    @apply flex items-center
}

.iysStepGrid {
    @apply grid grid-cols-10 mb-5
}

.iysStepColspan {
    @apply col-span-7 flexCenter lg:col-span-8
}

.iysServicesBox{
    @apply col-span-4 bg-white rounded-md border-b-4 border-transparent shadow-lg transition-all duration-500 cursor-pointer  lg:col-span-1 hover:border-makdosYellow hover:border-b-4 hover:shadow-2xl
}