@tailwind base;
@tailwind components;
@tailwind utilities;


@font-face {
    font-family: 'Poppins';
    src: url("/fonts/Poppins-Regular.woff");
    font-style: normal;
    font-weight: 400;
    font-display: swap;
  }
  @font-face {
    font-family: 'Poppins';
    src: url("/fonts/Poppins-Bold.woff");
    font-style: normal;
    font-weight: bold;
    font-display: swap;
  }
  @font-face {
    font-family: 'Poppins';
    src: url("/fonts/Poppins-SemiBold.woff");
    font-style: normal;
    font-weight: 600;
    font-display: swap;
  }
  @font-face {
    font-family: 'Poppins';
    src: url("/fonts/Poppins-Medium.woff");
    font-style: normal;
    font-weight: 500;
    font-display: swap;
  }
  

.max-w-8xl {
    max-width: 83rem;
}

html {
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
}

input:focus {
    outline: none;
}

.slick-dots li {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 10px;
    margin-right: 3px;
    margin-left: 3px;
    padding: 0;
    cursor: pointer;
    transition: width 0.3s ease-in-out;
}

.slick-slide {
    outline: none;
    
}


.text-after-shadow-h1::after {
    content: '';
    position: absolute;
    border-bottom: 18px solid rgba(255, 221, 0, 0.3);
    width: 92%;
    left: -1%;
    bottom: -3%;
    z-index: -40;
}


.text-after-shadow::after {
    content: '';
    position: absolute;
    border-bottom: 18px solid rgba(255, 221, 0, 0.30);
    width: 106%;
    left: -3%;
    bottom: 10%;
    z-index: -40;
}

.text-after-shadow-virtual-server::after {
    content: '';
    position: absolute;
    border-bottom: 18px solid rgba(255, 221, 0, 0.30);
    width: 90%;
    left: -3%;
    bottom: 10%;
    z-index: -40;
}

.text-after-shadow-iys::after {
    content: '';
    position: absolute;
    border-bottom: 18px solid rgba(255, 221, 0, 0.30);
    width: 24%;
    left: 38%;
    bottom: 10%;
    z-index: -40;
}

.text-after-shadow-iys5::after {
    content: '';
    position: absolute;
    border-bottom: 18px solid rgba(255, 221, 0, 0.30);
    width: 30%;
    left: 35%;
    bottom: 10%;
    z-index: -40;
}

.text-after-shadow-email::after {
    content: '';
    position: absolute;
    border-bottom: 18px solid rgba(255, 221, 0, 0.30);
    width: 60%;
    left: 20%;
    bottom: 10%;
    z-index: -40;
}

.text-after-shadow-email2::after {
    content: '';
    position: absolute;
    border-bottom: 18px solid rgba(255, 221, 0, 0.30);
    width: 28%;
    left: 36%;
    bottom: 10%;
    z-index: -40;
}

.text-after-shadow-email3::after {
    content: '';
    position: absolute;
    border-bottom: 18px solid rgba(255, 221, 0, 0.30);
    width: 32%;
    left: 34%;
    bottom: 18%;
    z-index: -40;
}

.text-after-shadow-email4::after {
    content: '';
    position: absolute;
    border-bottom: 18px solid rgba(255, 221, 0, 0.30);
    width: 46%;
    left: 27%;
    bottom: 18%;
    z-index: -40;
}

.text-after-shadow-cloud::after {
    content: '';
    position: absolute;
    border-bottom: 18px solid rgba(255, 221, 0, 0.30);
    width: 28%;
    left: 36%;
    bottom: 10%;
    z-index: -40;
}

.text-after-shadow-cloud2::after {
    content: '';
    position: absolute;
    border-bottom: 18px solid rgba(255, 221, 0, 0.30);
    width: 74%;
    left: 13%;
    bottom: 10%;
    z-index: -40;
}

.text-after-shadow-cloud3::after {
    content: '';
    position: absolute;
    border-bottom: 18px solid rgba(255, 221, 0, 0.30);
    width: 70%;
    left: 15%;
    bottom: 10%;
    z-index: -40;
}

.text-after-shadow-cloud4::after {
    content: '';
    position: absolute;
    border-bottom: 18px solid rgba(255, 221, 0, 0.30);
    width: 86%;
    left: 1%;
    bottom: 10%;
    z-index: -40;
}

.text-after-shadow-cloud5::after {
    content: '';
    position: absolute;
    border-bottom: 18px solid rgba(255, 221, 0, 0.30);
    width: 72%;
    left: 14%;
    bottom: 10%;
    z-index: -40;
}

@media (max-width: 768px) { 
    .text-after-shadow-email::after {
        content: '';
        position: absolute;
        border-bottom: 18px solid rgba(255, 221, 0, 0.30);
        width: 78%;
        left: 1%;
        bottom: 10%;
        z-index: -40;
    }

    .text-after-shadow-email2::after {
        content: '';
        position: absolute;
        border-bottom: 18px solid rgba(255, 221, 0, 0.30);
        width: 72%;
        left: 1%;
        bottom: 10%;
        z-index: -40;
    }

    .text-after-shadow-email3::after {
        content: '';
        position: absolute;
        border-bottom: 18px solid rgba(255, 221, 0, 0.30);
        width: 85%;
        left: 1%;
        bottom: 18%;
        z-index: -40;
    }
    
    .text-after-shadow-email4::after {
        content: '';
        position: absolute;
        border-bottom: 18px solid rgba(255, 221, 0, 0.30);
        width: 28%;
        left: 35%;
        bottom: 5%;
        z-index: -40;
    }

    .text-after-shadow-iys::after {
        border-bottom: 12px solid rgba(255, 221, 0, 0.30); 
        width: 70%; 
        left: 15%; 
        bottom: 8%;
    }

    .text-after-shadow-iys2::after {
        border-bottom: 12px solid rgba(255, 221, 0, 0.30); 
        width: 50%; 
        left: 5%; 
        bottom: 8%;
    }

    .text-after-shadow-iys5::after {
        content: '';
        position: absolute;
        border-bottom: 18px solid rgba(255, 221, 0, 0.30);
        width: 80%;
        left: 10%;
        bottom: 10%;
        z-index: -40;
    }

    .text-after-shadow-iys6::after {
        content: '';
        position: absolute;
        border-bottom: 18px solid rgba(255, 221, 0, 0.30);
        width: 22%;
        left:39%;
        bottom: 10%;
        z-index: -40;
    }
    .text-after-shadow-iys4::after {
        content: '';
        position: absolute;
        border-bottom: 12px solid rgba(255, 221, 0, 0.30);
        width: 0%;
        left: 35%;
        bottom: 10%;
        z-index: -40;
    }
   
    .text-after-shadow-cloud::after {
        content: '';
        position: absolute;
        border-bottom: 18px solid rgba(255, 221, 0, 0.30);
        width: 72%;
        left: 13%;
        bottom: 10%;
        z-index: -40;
    }
    
    .text-after-shadow-cloud2::after {
        content: '';
        position: absolute;
        border-bottom: 18px solid rgba(255, 221, 0, 0.30);
        width: 44%;
        left: 28%;
        bottom: 10%;
        z-index: -40;
    }

    .text-after-shadow-cloud5::after {
        content: '';
        position: absolute;
        border-bottom: 18px solid rgba(255, 221, 0, 0.30);
        width: 94%;
        left: 3%;
        bottom: 10%;
        z-index: -40;
    }
    
    .text-after-shadow-cloud3::after {
        content: '';
        position: absolute;
        border-bottom: 18px solid rgba(255, 221, 0, 0.30);
        width: 36%;
        left: 32%;
        bottom: 10%;
        z-index: -40;
    }
    
}

.text-after-shadow-iys2::after {
    content: '';
    position: absolute;
    border-bottom: 18px solid rgba(255, 221, 0, 0.30);
    width: 80%;
    left: -1%;
    bottom: 10%;
    z-index: -40;
}

.text-after-shadow-iys3::after {
    content: '';
    position: absolute;
    border-bottom: 18px solid rgba(255, 221, 0, 0.30);
    width: 46%;
    left: 28%;
    bottom: 10%;
    z-index: -40;
}

.text-after-shadow-iys4::after {
    content: '';
    position: absolute;
    border-bottom: 18px solid rgba(255, 221, 0, 0.30);
    width: 40%;
    left: 35%;
    bottom: 10%;
    z-index: -40;
}

.text-after-shadow-iys6::after {
    content: '';
    position: absolute;
    border-bottom: 18px solid rgba(255, 221, 0, 0.30);
    width: 22%;
    left:39%;
    bottom: 10%;
    z-index: -40;
}

.scroll-animate {
    opacity: 0;
    transform: translateX(-50px);
    transition: opacity 1s ease-out, transform 1s ease-out;
}

.scroll-animate.animate {
    opacity: 1;
    transform: translateX(0);
}


.animate-on-scroll {
    opacity: 0;
    transform: translateX(20px);
    transition: opacity 0.7s ease-out, transform 2s ease-out;
  }
  
  .animate-on-scroll.visible {
    opacity: 1;
    transform: translateX(90px);
  }

  @keyframes fill {
    0% {
        width: 0%;
    }
    100% {
        width: 100%;
    }
}

.animated-container .profile {
    opacity: 0;
    transform: translateY(50px);
    transition: all 1s ease-out;
  }
  
  .animated-container.animate .profile-1 {
    opacity: 1;
    transform: translateY(0);
  }
  
  .animated-container.animate .profile-2 {
    opacity: 1;
    transform: translateY(0);
  }
  
  .animated-container.animate .profile-3 {
    opacity: 1;
    transform: translateY(0);
  }
  
  .animated-container.animate .profile-4 {
    opacity: 1;
    transform: translateY(0);
  }
  
  .animated-container.animate .profile-5 {
    opacity: 1;
    transform: translateY(0);
  }

@keyframes rotate {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.rotating {
    transition: transform 1s ease-in-out;
}

.rotating.spin {
    animation: rotate 10s linear infinite; 
}

.rotatingServices{
    transition: transform 1s ease-in-out;
}

.rotatingServices.spin {
    animation: rotate 5s linear infinite; 
}


/* @keyframes blink { 50% { fill: transparent }}
.dot { 
  animation: 1s blink infinite;
  fill: grey;
}
.dot:nth-child(2) { animation-delay: 250ms }
.dot:nth-child(3) { animation-delay: 500ms } */

.dot {
    animation: blink 3s infinite ease-in-out;
    opacity: 0; /* Başlangıçta görünmez */
  }
  
  /* Her bir nokta için farklı başlangıç gecikmeleri */
  .dot:nth-child(1) {
    animation-delay: 0s;
  }
  .dot:nth-child(2) {
    animation-delay: 1s; /* İkinci nokta biraz gecikmeli başlar */
  }
  .dot:nth-child(3) {
    animation-delay: 2s; /* Üçüncü nokta daha fazla gecikmeli başlar */
  }
  
  /* Animasyon Tanımı */
  @keyframes blink {
    0%, 20% {
      opacity: 0; /* Görünmez */
      transform: scale(0.8); /* Hafif küçülme */
    }
    50% {
      opacity: 1; /* Tam görünür */
      transform: scale(1); /* Normal boyut */
    }
    100% {
      opacity: 0; /* Tekrar kaybolur */
      transform: scale(0.8); /* Küçülerek kaybolur */
    }
  }

  /* Animasyon Tanımı */
  @keyframes fadeIn {
    0% {
      opacity: 0;
      transform: translateY(20px); /* Yukarı doğru hareket başlat */
    }
    100% {
      opacity: 1;
      transform: translateY(0); /* Normal konuma dön */
    }
  }

span.switcher {
    position: relative;
    width: 140px;
    height: 35px;
    border-radius: 25px;
    margin: 20px 0;
    font-size: 12px;
}

span.switcher input {
    appearance: none;
    position: relative;
    width: 130px;
    height: 35px;
    border-radius: 25px;
    background-color: white;
    outline: none;
    font-family: 'Poppins', sans-serif;
}

span.switcher input:before,
span.switcher input:after {
    z-index: 2;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    color: #F1F1F1;
}

span.switcher input:before {
    content: 'Aylık';
    left: 15px;
    font-weight: bold;
}

span.switcher input:after {
    content: 'Yıllık';
    right: 15px;
    font-weight: bold;
}

span.switcher label {
    z-index: 1;
    position: absolute;
    top: 10px;
    bottom: 10px;
    border-radius: 20px;
}

span.switcher.switcher-1 input {
    transition: 0.25s -0.1s;
}

span.switcher.switcher-1 input:checked {
    background-color: #F1F1F1;
    background-image: none;

}

span.switcher.switcher-1 input:checked:before {
    color: #1e1e1e;
    transition: color 0.5s 0.2s;
}

span.switcher.switcher-1 input:checked:after {
    color: #ccc;
    transition: color 0.5s;
}

span.switcher.switcher-1 input:checked + label {
    left: 10px;
    right: 80px;
    background: white;
    transition: left 0.5s, right 0.4s 0.2s;
}

span.switcher.switcher-1 input:not(:checked) {
    background: #F1F1F1;
    transition: background 0.5s -0.1s;
}

span.switcher.switcher-1 input:not(:checked):before {
    color: #ccc;
    transition: color 0.5s;
}

span.switcher.switcher-1 input:not(:checked):after {
    color: #1e1e1e;
    transition: color 0.5s 0.2s;
}

span.switcher.switcher-1 input:not(:checked) + label {
    left: 80px;
    right: 10px;
    background: #fff;
    transition: left 0.4s 0.2s, right 0.5s, background 0.35s -0.1s;
}


@keyframes turn-on {
    0% {
        left: 100%;
    }

    100% {
        left: 0%;
    }
}

@keyframes turn-off {
    0% {
        right: 100%;
    }

    100% {
        right: 0%;
    }
}

/* Accordion  */
.accordionCheck {
    position: absolute;
    opacity: 0;
    z-index: -1;
}

.tabs {
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 4px 4px -2px rgba(0, 0, 0, 0.2);
}

.tab {
    width: 100%;
    color: #101E26;
    overflow: hidden;
}

.tab-label {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1em;
    background: White;
    font-weight: bold;
    cursor: pointer;
    /* Icon */
}

.tab-label:hover {
    background: white;
}

.tab-label::after {
    content: url(/accordion2.png);
    width: 1em;
    height: 1em;
    text-align: center;
    transition: all 0.5s;
    margin-right: 25px;
    margin-bottom: 25px;
}

.tab-content {
    max-height: 0;
    padding: 0 1em;
    color: #2c3e50;
    transition: all 0.35s;
    background: white;

}

.tab-close {
    display: flex;
    justify-content: flex-end;
    padding: 1em;
    font-size: 0.75em;
    background: white;
    cursor: pointer;
}

.tab-close:hover {
    background: white;
}

input:checked + .tab-label {
    background: white;
}

input:checked + .tab-label::after {
    content: url(/accordion.png);

}

input:checked ~ .tab-content {
    max-height: 140vh;
    padding: 1em;
}


/* Accordion End */

/* Radio Button */
[type="radio"]:checked,
[type="radio"]:not(:checked) {
    position: absolute;
    left: -9999px;
}

[type="radio"]:checked + label,
[type="radio"]:not(:checked) + label {
    position: relative;
    padding-left: 28px;
    cursor: pointer;
    line-height: 20px;
    display: inline-block;
    color: #666;
}

[type="radio"]:checked + label:before,
[type="radio"]:not(:checked) + label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 18px;
    height: 18px;
    border: 1px solid #ddd;
    border-radius: 100%;
    background: #fff;
}

[type="radio"]:checked + label:after,
[type="radio"]:not(:checked) + label:after {
    content: '';
    width: 10px;
    height: 10px;
    background: #FFDD00;
    position: absolute;
    top: 4px;
    left: 4px;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
}

[type="radio"]:not(:checked) + label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
}

[type="radio"]:checked + label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
}

/* Radio Button End */

/* Checkbox */
.checkInput + label {
    display: block;
    margin: 0.2em;
    cursor: pointer;
    padding: 0.2em;
}

.checkInput {
    display: none;
}

.checkInput + label:before {
    content: "\2714";
    border: 0.1em solid #000;
    border-radius: 0.2em;
    display: inline-block;
    width: 20px;
    height: 20px;
    padding-left: 0.2em;
    padding-bottom: 0.6em;
    margin-right: 0.2em;
    vertical-align: bottom;
    color: transparent;
    transition: .2s;
}

.checkInput + label:active:before {
    transform: scale(0);
}

.checkInput:checked + label:after {
    margin-right: 10px;
}

.checkInput:checked + label:before {
    background-color: #FFDD00;
    border-color: #FFDD00;
    color: black;
}

.checkInput:disabled + label:before {
    transform: scale(1);
    border-color: #aaa;
}

.checkInput:checked:disabled + label:before {
    transform: scale(1);
    background-color: #bfb;
    border-color: #bfb;
}

/* Checkbox End */


/* React Tabs */
.react-tabs {
    -webkit-tap-highlight-color: transparent;
    width: 100%;
}

.react-tabs__tab-list {
    margin: 14px 0;
    padding: 0;
}

.react-tabs__tab {
    display: inline-block;
    border-bottom: none;
    position: relative;
    list-style: none;
    cursor: pointer;
    color: #242126;
    font-size: 14px;
    opacity: 0.5;
    margin-right: 24px;
    background-color: white;
    padding: 10px 13px 10px 13px;
    border-radius: 4px;
}

.react-tabs__tab:focus {
    outline: none;
    border: none;
}

.react-tabs__tab--selected {
    opacity: 1;
    background-color: #FFDD00;
}

.react-tabs__tab--selected:after {
    padding-left: 12px;
    opacity: 0.5;
}

.react-tabs__tab--disabled {
    cursor: default;
}

.react-tabs__tab-panel {
    display: none;
}

.react-tabs__tab-panel--selected {
    display: block;
}

/* React Tabs  End*/

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}


::-webkit-calendar-picker-indicator {

    color: gray;
    opacity: .3
}

::-webkit-calendar-picker-indicator:focus {

    outline: none;
}

.date::-webkit-calendar-picker-indicator::after {

    content: '';
    display: block;
    background: url(/*yourURLHere*/) no-repeat;
    background-size: 10%;
    width: 100px;
    height: 100px;
    position: absolute;
    transform: translateX(-2%);

}

/* Swich */
.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 28px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 20px;
    width: 20px;
    left: 9px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}

.switchInput:checked + .slider {
    background-color: #45D85B;
}

.switchInput:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
}

.switchInput:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
    border-radius: 40px;
}

.slider.round:before {
    border-radius: 50%;
}

/* switch end */

#paybutton {
    width: 152px;
    height: 40px;
    border-radius: 4px;
    background-color: #FFDD00;
    margin-top: 20px;


}



.loader {
    border-top-color: #FFDD00;
    -webkit-animation: spinner 1.5s linear infinite;
    animation: spinner 1.5s linear infinite;
}

@-webkit-keyframes spinner {
    0% {
        -webkit-transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spinner {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}


.grayTriangle {
    border-style: solid;
    border-color: transparent transparent #979a9e transparent;
    display: inline-block;
    height: 0;
    width: 0;
}

::-moz-selection {
    color: #192E3A;
    background: #fff08e;
}

::selection {
    color: #192E3A;
    background: #fff08e;
}


/* custom scroll */
.customScroll::-webkit-scrollbar {
    width: 5px;
    border-radius: 15px;
    height: 4px;
}

.customScroll::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 15px;
}

.customScroll::-webkit-scrollbar-thumb {
    background: #d5d5d5;
    border-radius: 15px;
}

.customScroll::-webkit-scrollbar-thumb:hover {
    background: #98a0a9;
}
  

/* custom scroll end */

/* custom  scrollbar */

/*       ScrollBar 1        */

.scrollbar-custom::-webkit-scrollbar {
    width: 14px;
}

.scrollbar-custom::-webkit-scrollbar-track {
    border-radius: 8px;
    background-color: #ffffff;
    border: 1px solid #ffffff;
}

.scrollbar-custom::-webkit-scrollbar-thumb {
    border-radius: 8px;
  border: 4px solid transparent;
   background-clip: content-box;
    background-color: #FFDD00;
}
/* custom scrollbar end*/
.colocationCard >p> span {
    color:rgba(255, 255, 255, 0.836) !important
}

.terms > ul > li{
    list-style: disc;
    margin-left: 20px;
}